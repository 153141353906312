import { Fragment } from '@fullcalendar/core/preact';
import { lazy, LazyExoticComponent, Suspense, useEffect } from 'react';
import { Outlet, Route, RouteObject, useLocation, useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import BlankLayout from '../components/Layouts/BlankLayout';
import Loader from '../components/Loader';

type UserRole = '1' | '2';

interface RouteProps {
    path?: string;
    element?: LazyExoticComponent<() => JSX.Element> | null;
    guard?: LazyExoticComponent<(props: { children: React.ReactNode }) => JSX.Element> | null;
    layout?: 'blank' | 'default';
    children?: RouteProps[];
    requiredRoles?: UserRole[];
}
function parseBooleanFromString(value: string): boolean {
    const lowercasedValue = value.toLowerCase();
    return lowercasedValue === 'true' || lowercasedValue === '1' || lowercasedValue === 'yes';
}
// function isAuthenticated(requiredRoles: UserRole[]): boolean {
//     const authStatus = localStorage.getItem('auth');
//     const isAuthenticatedFromLocalStorage = authStatus ? parseBooleanFromString(authStatus) : false;
//     const userRole = localStorage.getItem('user_rol_id') as UserRole;
//     return isAuthenticatedFromLocalStorage && userRole !== null;
// }
function isAuthenticated(requiredRoles: UserRole[]): boolean {
    const authStatus = localStorage.getItem('auth');
    const token = localStorage.getItem('andoToken');
    const userRole = localStorage.getItem('user_rol_id') as UserRole;

    const isAuthenticatedFromLocalStorage = authStatus === 'true' && token !== null;

    if (!isAuthenticatedFromLocalStorage) {
        return false;
    }

    // Si se requieren roles específicos, verificarlos
    if (requiredRoles.length > 0) {
        return userRole !== null && requiredRoles.includes(userRole);
    }

    return true;
}
const UnauthorizedComponent: React.FC<any> = (route) => {
    const authStatus = localStorage.getItem('auth');
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (authStatus) {
            navigate('/auth/login');
        } else if (location?.pathname && route.path != '*') {
            localStorage.setItem('location_path', location.pathname);
            navigate('/auth/login');
        } else {
            navigate('/auth');
        }
    }, [authStatus, navigate]);

    return null;
};

export const renderRoutes = (routes: RouteProps[]) => {
    return routes.map((route, index) => {
        const Component = route.element || Fragment;
        const Guard = route.guard || Fragment;
        const Layout = route.layout === 'blank' ? BlankLayout : DefaultLayout;
        const shouldApplyLayout = !route.path || !route.path.includes('auth');

        // Check if the route should be publicly accessible (like '/dashboard')
        const isPublicRoute = route.path === '/dashboard';

        return (
            <Route
                key={index}
                path={route.path}
                element={
                    <Suspense>
                        <Guard>
                            {route.children ? (
                                <Outlet />
                            ) : shouldApplyLayout ? (
                                route.path === '/error_route' || isPublicRoute ? (
                                    <Layout>
                                        <Component />
                                    </Layout>
                                ) : isAuthenticated(route.requiredRoles || []) ? (
                                    <Layout>
                                        <Component />
                                    </Layout>
                                ) : (
                                    <UnauthorizedComponent route={route} />
                                )
                            ) : (
                                <Component />
                            )}
                        </Guard>
                    </Suspense>
                }
            >
                {route.children && renderRoutes(route.children)}
            </Route>
        );
    });
};

export const convertRoutes = (routes: RouteProps[]): RouteObject[] => {
    return routes.map((route, index) => {
        const Component = route.element || Fragment;
        const Guard = route.guard || Fragment;
        const Layout = route.layout === 'blank' ? BlankLayout : DefaultLayout;
        const shouldApplyLayout = !route.path || !route.path.includes('auth');

        // Check if the route should be publicly accessible (like '/dashboard')
        const isPublicRoute = route.path === '/dashboard';

        return {
            path: route.path,
            element: (
                <Suspense
                    fallback={
                        <div className="w-screen h-screen flex justify-center items-center">
                            <Loader />
                        </div>
                    }
                >
                    <Guard>
                        {route.children ? (
                            <Outlet />
                        ) : shouldApplyLayout ? (
                            route.path === '/error_route' || isPublicRoute ? (
                                <Layout>
                                    <Component />
                                </Layout>
                            ) : isAuthenticated(route.requiredRoles || []) ? (
                                <Layout>
                                    <Component />
                                </Layout>
                            ) : (
                                <UnauthorizedComponent route={route} />
                            )
                        ) : (
                            <Component />
                        )}
                    </Guard>
                </Suspense>
            ),
            children: route.children ? convertRoutes(route.children) : undefined,
        };
    });
};

export const routes: RouteProps[] = [
    {
        path: 'auth/register',
        element: lazy(async () => await import('../pages/Authentication/Login')),
        requiredRoles: ['1', '2'],
        layout: 'blank',
    },
    {
        path: 'auth/:token',
        element: lazy(async () => await import('../pages/Authentication/Login')),
        requiredRoles: ['1', '2'],
        layout: 'blank',
    },
    {
        path: 'auth/login',
        element: lazy(async () => await import('../pages/Authentication/Login')),
        requiredRoles: ['1', '2'],
        layout: 'blank',
    },
    {
        path: 'config/home/:token',
        element: lazy(() => import('../pages/Apps/Home')),
        requiredRoles: ['1', '2'],
        layout: 'blank',
    },
    {
        path: '/error_route',
        element: lazy(async () => await import('../pages/Pages/ErrorRoute')),
        layout: 'blank',
        requiredRoles: ['1', '2'],
    },
    {
        path: '/auth/MeLi',
        element: lazy(async () => await import('../pages/Authentication/MeliRedirectionLanding/MeliRedirectionLogin')),
        requiredRoles: ['1', '2'],
        layout: 'blank',
    },
    {
        path: '/auth/MeLi/panel',
        element: lazy(async () => await import('../pages/Authentication/MeLiRegisterComponent/MeliPanel')),
        requiredRoles: ['1', '2'],
        layout: 'blank',
    },
    {
        path: '/dashboard',
        element: lazy(() => import('../pages/Apps/Dashboard/Dashboard')),
        layout: 'blank',
        requiredRoles: ['1', '2'],
    },

    {
        guard: lazy(async () => await import('../pages/guards/AuthGuard')),

        children: [
            {
                path: '/',
                element: lazy(async () => await import('../pages/Apps/Home/Home')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/multiagente/usuarios',
                element: lazy(async () => await import('../pages/Apps/Users/UsersView')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/test/menu/:id',
                element: lazy(async () => await import('../pages/Apps/TestView/TestViewMenu/TestViewMenu')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/meli/connect',
                element: lazy(async () => await import('../pages/Apps/MeliTutorial/MeliTutorial')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/tutorial',
                element: lazy(async () => await import('../pages/Apps/Stepper/StepperDashboard')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
            {
                path: '*',
                element: lazy(async () => await import('../pages/Pages/ErrorRouteInvalid')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/clients',
                element: lazy(() => import('../pages/Apps/ConfigClients')),
                requiredRoles: ['1'],
            },
            {
                path: '/multiagente',
                element: lazy(() => import('../pages/Apps/multiagente/multiagenteDashboard')),
                requiredRoles: ['1', '2'],
            },
            // {
            //     path: '/panel-ia',
            //     element: lazy(() => import('../pages/Apps/ContextIa')),
            //     requiredRoles: ['1', '2'],
            // },

            {
                path: '/chatbot/list',
                element: lazy(() => import('../pages/Apps/MenuRegister/ListMenusRegister/ListMenusRegister')),
                requiredRoles: ['1', '2'],
            },
            // {
            //     path: '/chatbot/chatbot-onboarding',
            //     element: lazy(() => import('../pages/OnboardingChatbot/IaOnboardingSimple')),
            //     requiredRoles: ['1', '2'],
            // },
            {
                path: '/chatbot/:id',
                element: lazy(async () => await import('../pages/Flow/Flow')),
                requiredRoles: ['1', '2'],
            },
            {
                path: '/chatbot/flow',
                element: lazy(async () => await import('../pages/Flow/Flow')),
                requiredRoles: ['1', '2'],
            },
            {
                path: '/chatbot/ias',
                element: lazy(() => import('../pages/Apps/Assistants')),
                requiredRoles: ['1', '2'],
            },
            {
                path: '/crm/config',
                element: lazy(() => import('../pages/Apps/crm/crmDashboard')),
                requiredRoles: ['1', '2'],
            },
            {
                path: '/crm/usuarios',
                element: lazy(async () => await import('../pages/Apps/Users/UsersView')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: 'config/home/:token',
                element: lazy(() => import('../pages/Apps/Home')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/tutorial',
                element: lazy(() => import('../pages/Pages/TutorialLink')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },

            // Asistentes

            {
                path: '/chatbot/teléfonos',
                element: lazy(() => import('../pages/Apps/WspPanel/WspPanel')),
                requiredRoles: ['1', '2'],
            },
            {
                path: '/registro/usuarios',
                element: lazy(() => import('../pages/Apps/UserRegister/UserRegister')),
                requiredRoles: ['1', '2'],
            },
            // {
            //     path: '/registro/menu',
            //     element: lazy(() => import('../pages/Apps/MenuRegister/ConfigMenuStyled')),
            //     requiredRoles: ['1', '2'],
            // },
            {
                path: '/pages/faq',
                element: lazy(() => import('../pages/Pages/Faq')),
                requiredRoles: ['1', '2'],
            },
            {
                path: '/pages/coming-soon',
                element: lazy(() => import('../pages/Pages/ComingSoon')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/pages/error404',
                element: lazy(() => import('../pages/Pages/Error404')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/pages/error500',
                element: lazy(() => import('../pages/Pages/Error500')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/pages/error503',
                element: lazy(() => import('../pages/Pages/Error503')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/pages/maintenence',
                element: lazy(() => import('../pages/Pages/Maintenence')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/steps',
                element: lazy(() => import('../pages/Lobby')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },

            {
                path: '/redirect/cromo',
                element: lazy(() => import('../pages/Apps/RedirectCromo')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/redirect/bota',
                element: lazy(() => import('../pages/Apps/RedirectBota')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/agents',
                element: lazy(() => import('../pages/Apps/Agents/Agents')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/meli',
                element: lazy(() => import('../pages/Apps/Meli/Meli')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/meli/numbers',
                element: lazy(() => import('../pages/Apps/Meli/MeLIDashboard/MeLiDashboardComponent')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/meli/answers',
                element: lazy(() => import('../pages/Apps/Meli/MeliDefaultAnswers/MeliDefaultAnswers')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/meli/config',
                element: lazy(() => import('../pages/Apps/Meli/MeliConfig')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/multiagente/areas',
                element: lazy(() => import('../pages/Apps/Areas/AreasSection')),
                layout: 'default',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/newChatBot',
                element: lazy(() => import('../pages/Apps/NewChatBot/NewChatBotSection')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
            {
                path: '/newWpConnect',
                element: lazy(() => import('../pages/Apps/NewWpConnect/NewWpConnectSection')),
                layout: 'blank',
                requiredRoles: ['1', '2'],
            },
        ],
    },
];
