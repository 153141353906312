import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

interface ModalProps {
    isOpen?: any;
    onClose?: any;
    title?: any;
    children?: any;
    showX?: boolean;
    isRounded?: boolean;
    boxSize?: string
}
export const Modal = ({ isOpen, onClose, title, children, showX = true ,isRounded, boxSize}: ModalProps) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" open={isOpen} onClose={onClose} className="relative z-50">
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-[black]/60" />
                </Transition.Child>

                <div className={`fixed inset-0 overflow-y-auto ${boxSize ?? 'max-w-2xl xl:max-w-none'} m-auto `}>
                    <div className="flex min-h-full items-center justify-center px-4 ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={` panel border-0 p-0 ${isRounded ?'rounded-3xl' :'rounded-lg'} overflow-hidden w-full max-w-6xl text-black dark:text-white-dark`}>
                                {showX && <button type="button" onClick={onClose} className="absolute top-3 ltr:right-4 rtl:left-4 text-gray-400 hover:text-gray-800 dark:hover:text-gray-600 outline-none">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </button>}
                                <div className="flex items- flex-wrap flex-col text-lg font-medium bg-[#fbfbfb] dark:bg-[#121c2c] ltr:pl-5 rtl:pr-5  ltr:pr-[10px] rtl:pl-[10px]">
                                    <h4 className={`py-2 w-full font-semibold ${title && 'border-b-2 text-gray-500  border-secondary/10'}  text-2xl`}>
                                        {title}
                                    </h4>

                                    <div className=" py-4 text-left px-2 w-full">
                                        <div className="w-full">
                                            <div className="flex flex-row justify-between items-start h-full w-full">
                                                <div className="flex flex-col  w-full">
                                                    {children}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
